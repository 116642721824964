var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first pb-0"
  }, [_c('page-section', {
    staticClass: "page-section--xs py-0"
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "/images/sub/interir/interir-img.jpg",
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 1330 / 272 : 300 / 300
    }
  }, [_c('div', {
    staticClass: "d-flex align-center h-100 pa-20px py-md-30px px-lg-60px"
  }, [_c('div', {
    staticClass: "w-100 white--text text-center"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-divider', {
    staticClass: "grey lighten-5 mw-130px ml-auto"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("홈 스타일링 큐레이팅 서비스")])]), _c('v-col', [_c('v-divider', {
    staticClass: "grey lighten-5 mw-130px"
  })], 1)], 1), _c('h3', {
    staticClass: "tit tit--xs mt-4px"
  }, [_vm._v("더 이상 상상만 하지 마세요.")]), _c('p', {
    staticClass: "txt txt--sm white--text mt-10px mt-md-20px"
  }, [_vm._v(" 인스타에서 봤던 예쁜 집, 우리집이 될 순 없을까? 더 이상 고민만, 상상만 하지 마세요. "), _c('br'), _vm._v(" 내 취향과 스타일만 알려주시면 나머지는 저희가 알려드리겠습니다. 고민중이시라면 지금 바로 시작해 보세요. ")])], 1)])])], 1)], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('v-row', _vm._l(_vm.process, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": "4"
      }
    }, [_c('v-card', [_c('v-img', {
      attrs: {
        "cover": "",
        "src": item.image,
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "d-flex align-center h-100 pa-20px py-md-30px px-lg-60px"
    }, [_c('div', {
      staticClass: "w-100 white--text text-center"
    }, [_c('p', {
      staticClass: "txt txt--sm white--text mt-10px mt-md-20px"
    }, [_vm._v(" STEP " + _vm._s(index + 1) + " ")]), _c('h3', {
      staticClass: "tit tit--xs mt-4px"
    }, [_vm._v(_vm._s(item.title))])])])])], 1), _c('div', {
      staticClass: "pt-12px pt-md-16px"
    }, [_c('h3', {
      staticClass: "tit tit--xs",
      domProps: {
        "innerHTML": _vm._s(item.subTitle)
      }
    }), _c('p', {
      staticClass: "txt txt--sm txt--dark mt-4px mt-md-8px",
      domProps: {
        "innerHTML": _vm._s(item.txt)
      }
    })])], 1);
  }), 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section pb-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "8"
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "/images/sub/interir/interir-img4.jpg",
      "aspect-ratio": 880 / 400
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-space-between h-100 pa-20px pa-md-30px pa-lg-40px"
  }, [_c('h3', {
    staticClass: "white--text tit tit--xs"
  }, [_vm._v("3D 인테리어")]), _c('div', {
    staticClass: "w-100"
  }, [_c('v-img', {
    staticClass: "ml-auto",
    staticStyle: {
      "opacity": ".4"
    },
    attrs: {
      "src": "/images/logo-light.svg",
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 220 : 160,
      "aspect-ratio": 220 / 24,
      "contain": ""
    }
  })], 1)])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "pl-md-20px pl-lg-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("서비스 준비중입니다.")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-4px mt-md-8px"
  }, [_vm._v(" 나만의 감각으로 내 취향대로. 내 상상이 현실이 되는 공간. "), _c('br'), _vm._v(" 3D 메타버스 기술로 상상하던 공간을 눈으로 직접 확인하세요. "), _c('br'), _vm._v(" AI와 컨설팅 전문가가 추천해 주는 온라인 스타일링 서비스입니다. ")]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-md-200px",
    attrs: {
      "x-large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("3D 인테리어 시작하기")])], 1)])])], 1)], 1), _c('page-section', {
    staticClass: "page-section page-section--last"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("스타일 퀴즈")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 마음에 드는 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("스타일 이미지")]), _vm._v("를 "), _c('br'), _vm._v(" 선택해주세요 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("스타일 퀴즈")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 마음에 드는 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("수전 이미지")]), _vm._v("를 "), _c('br'), _vm._v(" 선택해주세요 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("스타일 퀴즈")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 마음에 드는 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("양변기 이미지")]), _vm._v("를 "), _c('br'), _vm._v(" 선택해주세요 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("스타일 퀴즈")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 마음에 드는 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("컬러와 색감")]), _vm._v("을 "), _c('br'), _vm._v(" 선택해주세요 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("스타일 퀴즈")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 마음에 드는 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("타일 재질")]), _vm._v(" "), _c('br'), _vm._v(" 선택해주세요 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("범위설정")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 공간을 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("디자인하는 이유")]), _vm._v("를 "), _c('br'), _vm._v(" 알려주세요 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("범위설정")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 원하는 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("디자인 범위")]), _vm._v("를 "), _c('br'), _vm._v(" 알려주세요 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("범위설정")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 선호하는 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("인테리어 작업방식")]), _vm._v("을 "), _c('br'), _vm._v(" 선택해주세요. ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "grey lighten-5 h-100"
  }, [_c('v-img', {
    attrs: {
      "cover": "",
      "src": "",
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-16 d-block"
  }, [_vm._v("favorite_border")])]), _c('v-col', [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("범위설정")])])], 1), _c('h3', {
    staticClass: "tit tit--xs mt-10px mt-md-20px"
  }, [_vm._v(" 분석결과 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("모던 클래식 스타일")]), _vm._v("을 "), _c('br'), _vm._v(" 추천해드려요 ")])], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }